<template>
  <div class="q-pa-md">
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
        <q-legend
          :label="$t('Inventory')"
          text-class="text-h6"
        />
        <search
          dense
          autoset
          is-expandable
          @submit="handleSearch"
        />
        <q-space/>

        <q-btn
          :color="serverParams.filter && serverParams.filter.length > 0 ? 'light-blue-9' : 'dark'"
          text-color="white"
          size="sm"
          class="q-mr-sm"
          :label="filterBtnText"
          no-caps
          unelevated
          @click="openCloseFilters"
        />

        <q-btn
          color="dark"
          text-color="white"
          :label="$t('Refresh')"
          size="sm"
          class="q-mr-sm"
          no-caps
          unelevated
          @click="refreshItems"
        />

      </q-card-section>

      <q-card-section class="q-ma-none q-pa-none">
        <filters-collapse
          :is-open="isOpenFilter"
          :options="{
            defaultFilter: serverParams.filter,
            fields: activatedFields,
          }"
          @submit="handleFiltersSubmit"
          @close="openCloseFilters"
        />
      </q-card-section>

      <q-card-section
        class="q-pa-none"
      >
        <q-table
            style="height: calc(100vh - 130px);"
            class="sticky-header-table"
            row-key="id"
            :rows-per-page-label="$t('Rows per page')"
            :rows="items"
            :columns="columns"
            v-model:pagination="pagination"
            :loading="itemsLoading"
            :filter="filter"
            :rows-per-page-options="[25, 50, 100, 150, 200, 250]"
            virtual-scroll
            binary-state-sort
            flat
            @request="onRequest"
        >
          <template v-slot:loading>
            <q-inner-loading
                    showing
                    color="primary"
            />
          </template>

          <template v-slot:body="props">
              <warehouse-list-row
                :data="props"
              />
          </template>
        </q-table>

      </q-card-section>
    </q-card>
  </div>
</template>

<script>

import Search from "../../components/search/Search.vue";
import TableMixin from "@/apps/app/components/global/TableMixin.vue";
import {buildQuery} from "@/apps/app/utils/query-utils";
import {mapActions} from "vuex";
import Template from "@/apps/app/pages/notifications/Template.vue";
import WarehouseListRow from "@/apps/app/components/shops-inventory/WarehouseListRow.vue";
import FiltersCollapse from "@/apps/app/components/filters/FilterCollapse.vue";

export default {
  name: 'WarehouseList',
  components: {
      FiltersCollapse,
      WarehouseListRow,
      Search
  },
  mixins: [
    TableMixin
  ],
  data() {
    return {
      filter: null,
      isOpenFilter: false,
      totalItems: 0,
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 50,
        rowsNumber: 50
      },
      items: [],
      itemsLoading: false,
      columns: [
        {
          label: '#',
          name: 'index',
          align: 'center'
        },
        {
          label: 'Warehouse',
          name: 'customer_warehouse',
          align: 'center'
        },
        // {
        //   label: 'Shipped',
        //   name: 'shipped',
        //   align: 'center'
        // },
        {
          label: 'On hand',
          name: 'normal',
          align: 'center',
          sortable: true
        },
        {
          label: 'Booked',
          name: 'booked',
          align: 'center'
        },
        {
          label: 'Blocked',
          name: 'blocked',
          align: 'center'
        },
        {
          label: 'Total',
          name: 'total',
          align: 'center',
          sortable: true
        }
      ],
      activatedFields: [
          'warehouse'
      ]

    }
  },
  mounted() {
    this.onRequest()
  },
  computed: {
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
          ? this.$t('Filtered: ') + this.totalItems
          : this.$t('Filter')
    },
  },
  methods: {
    ...mapActions([
        'loadWarehouses'
    ]),
    handleFiltersSubmit (filter) {
      let updatedFilter = [
          ...filter
      ]

      this.isOpenFilter = false
      return this.onRequest({ pagination: { filter: updatedFilter, page: 1 }})
    },
    openCloseFilters () {
        this.isOpenFilter = !this.isOpenFilter
    },
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    handleSearch (search) {
        return this.onRequest({ pagination: { page: 1, search } })
    },
    onRequest (data= {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      if (query.search && query.search[query.search.length - 1] !== '*' && query.search[query.search.length - 2] !== ':' && !query.search.includes('%')) {
          query.search += query.search[query.search.length - 1] === ':'
              ? '*'
              : ':*'
      }

      this.updateParams(query)
        this.$service.warehousesInventory.getAll(this.serverParams)
          .then(({items, page, totalItems }) => {
              this.pagination = {
                  ...this.pagination,
                  page,
                  rowsNumber: totalItems
              }
              this.totalItems= totalItems
              this.items=items
          })
    }
  }
}
</script>